.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite
    alternate-reverse;
  transform: translate3d(0, 0, 0);
  border-radius: 50%;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}
.App-header th {
  font-weight: 700;
}
.App-header-dark-mode {
  background-color: #4a505c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}
.App-header-dark-mode table {
  background-color: #282c34;
}

.App-header-dark-mode th {
  color: #fff;
  font-weight: 700;
}

.App-header-dark-mode td {
  color: #fff;
}
.App-header-dark-mode img {
  filter: invert(100%);
}

.App-link {
  color: #61dafb;
}

.cardHover {
  transition: all 1s;
}
.cardHover:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0% {
    transform: translate(-25%, -5%) rotate(-10deg);
  }
  100% {
    transform: translate(25%, -5%) rotate(30deg);
  }
}


/* MOSAIC */
.mosaic-window-body{
  overflow: scroll !important;
  background-color: #fff !important;
}

#userGrid, #restaurantGrid {
  height: calc(100vh - 116px); 
  width: 100%; 
  margin: 0;
}
